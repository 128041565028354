.casedot .slick-slide.slick-active.slick-current h6 {
    color: #FFA030;
}
.casedot .slick-slide.slick-active.slick-current p {
    color: #FFFFFF;
}

.casedot .slick-slide.slick-active.slick-current .casedotinner {
    border-color: #FFA030;
    transform: translateY(-10%);
}
.casedot .slick-initialized .slick-slide {padding-top: 20px;}
.casedot .slick-slide.slick-active.slick-cloned {opacity: 0;}

.casedot .slick-slider.slick-initialized .slick-list {padding:0px 0px !important;}

.casedot .slick-initialized .slick-slide {padding: 17px 10px !important;}